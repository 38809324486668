<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    class="pa-16"
  >
    <v-row>
      <v-col
        cols="12"
        lg="4"
        sm="12"
      >
        <material-card
          color="green darken-3"
          :number="lastReportData.recuperados"
          class="text-center"
        >
          <div class="pt-5 pb-3">
            <span>Recuperados</span>
          </div>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        lg="2"
        sm="6"
      >
        <material-card
          color="yellow darken-3"
          :number="lastReportData.isolamento"
          class="text-center"
        >
          <div class="pt-5 pb-3">
            <span>Isolados</span>
          </div>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        lg="2"
        sm="6"
      >
        <material-card
          color="orange darken-3"
          :number="lastReportData.internados"
          class="text-center"
        >
          <div class="pt-5 pb-3">
            <span>Internados</span>
          </div>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        lg="2"
        sm="6"
      >
        <material-card
          color="red darken-3"
          :number="lastReportData.internadosUti"
          class="text-center"
        >
          <div class="pt-5 pb-3">
            <span>Internados em UTI</span>
          </div>
        </material-card>
      </v-col>

      <v-col
        cols="12"
        lg="2"
        sm="6"
      >
        <material-card
          color="grey darken-3"
          :number="lastReportData.obitos"
          class="text-center"
        >
          <div class="pt-5 pb-3">
            <span>Óbitos</span>
          </div>
        </material-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <canvas
          id="graficoNovosCasos"
          height="300px"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <canvas
          id="graficoInternados"
          height="300px"
        />
      </v-col>
    </v-row>

    <v-row class="mt-12">
      <v-col cols="12 text-center">
        Dados atualizados em {{ lastReportData.date | date }} conforme relatórios oficiais publicados no site da Prefeitura Municipal de Pirassununga.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import MaterialCard from '@/components/base/MaterialCard'
  import Chart from 'chart.js'

  export default {
    name: 'Dashboard',

    components: { MaterialCard },

    filters: {
      date: function (value) {
        if (!value) return null

        const year = value.substr(0, 4)
        const month = value.substr(5, 2)
        const day = value.substr(8, 2)

        return day + '/' + month + '/' + year
      },
    },

    data () {
      return {
        reportsData: [],
        dadosDeNovosCasos: {
          type: 'bar',
          data: {
            labels: [],
            datasets: [{
              label: 'Novos casos por dia',
              data: [],
              backgroundColor: '#4527A0',
            }],
          },
          options: {
            maintainAspectRatio: false,
          },
        },
        dadosDeInternados: {
          type: 'bar',
          data: {
            labels: [],
            datasets: [
              {
                label: 'Internados',
                data: [],
                backgroundColor: 'rgba(239,108,0,1)',
              },
              {
                label: 'Internados em UTI',
                data: [],
                backgroundColor: 'rgba(198,40,40,1)',
              },
            ],
          },
          options: {
            maintainAspectRatio: false,
          },
        },
      }
    },

    computed: {
      lastReportData: function () {
        if (this.reportsData.length === 0) return {}

        return this.reportsData[this.reportsData.length - 1]
      },
    },

    created () {
      const db = this.$firebase.firestore()

      const reportsData = db.collection('reportsData')
        .orderBy('date')
      reportsData.get().then(snapshot => {
        this.reportsData = []
        snapshot.forEach(document => {
          this.reportsData.push(document.data())
        })
        this.loadChartsData()
        this.createChart('graficoNovosCasos', this.dadosDeNovosCasos)
        this.createChart('graficoInternados', this.dadosDeInternados)
      })

      this.$firebase.analytics().logEvent('page_view', 'dashboard')
    },

    methods: {
      loadChartsData () {
        for (let index = 1; index < this.reportsData.length; index++) {
          const currentReport = this.reportsData[index]
          const previousReport = this.reportsData[index - 1]

          const currentCases = this.sumReportCases(currentReport)
          const previousCases = this.sumReportCases(previousReport)

          const data = currentCases - previousCases
          const label = this.extractLabelFromDate(currentReport.date)

          this.dadosDeNovosCasos.data.labels.push(label)
          this.dadosDeNovosCasos.data.datasets[0].data.push(data)

          const internados = parseInt(currentReport.internados)
          const internadosUti = parseInt(currentReport.internadosUti)

          this.dadosDeInternados.data.labels.push(label)
          this.dadosDeInternados.data.datasets[0].data.push(internados)
          this.dadosDeInternados.data.datasets[1].data.push(internadosUti)
        }
      },

      sumReportCases (report) {
        // TODO Refatorar para salvar como tipo de dado correto no banco de dados
        return parseInt(report.isolamento) + parseInt(report.internados) + parseInt(report.internadosUti) + parseInt(report.recuperados) + parseInt(report.obitos)
      },

      extractLabelFromDate (date) {
        const month = date.substr(5, 2)
        const day = date.substr(8, 2)

        return day + '/' + month
      },

      createChart (chartId, chartData) {
        const ctx = document.getElementById(chartId)
        // eslint-disable-next-line
        const chart = new Chart(ctx, {
          type: chartData.type,
          data: chartData.data,
          options: chartData.options,
        })
      },
    },
  }
</script>
